<template>
  <ContactCardWrapper>
    <div class="contact-card">
      <figure>
        <img width="100" :src="require(`@/static/img/students/${student.gender.toLowerCase()}.png`)" alt="" />
        <figcaption>
          <h3>{{ student.firstname }} {{ student.lastname }}</h3>
          <span>{{ student.nickname ? `(${student.nickname}) ` : '' }}</span>
          <span>เลขที่: {{ student.studentNo }}</span>
        </figcaption>
      </figure>
    </div>
    <sdDropdown class="wide-dropdwon" :action="['click']">
      <template #overlay>
        <a @click="() => showEditModal(user, id)" to="#">
          <span>Edit</span>
        </a>
        <a @click="() => onHandleDelete(id)" to="#">
          <span>Delete</span>
        </a>
      </template>
      <sdButton class="btn-icon" type="light" to="#" shape="circle">
        <sdFeatherIcons type="more-horizontal" size="18" />
      </sdButton>
    </sdDropdown>
  </ContactCardWrapper>
</template>
<script>
// import { computed, toRefs } from 'vue';
import PropTypes from 'vue-types';
// import { useStore } from 'vuex';
import { ContactCardWrapper } from '../ContactStyle';

const StudentCard = {
  name: 'ContactCard',
  components: { ContactCardWrapper },
  props: {
    student: PropTypes.object,
    showEditModal: PropTypes.func,
  },
  setup() {
    return {};
  },
};

export default StudentCard;
</script>
