import Styled from 'vue3-styled-components';

const ConfirmGradeListWrapper = Styled.div`
    .notification-box-single{
        .ant-card{
            border: 1px solid ${({ theme }) => theme['border-color-light']};
        }
        .notification-header{
            margin-top: -8px;
            .notification-header__text{
                font-size: 15px;
                font-weight: 500;
                color: ${({ theme }) => theme['light-color']};
            }
            .btn-toggle{
                font-size: 13px;
                color: ${({ theme }) => theme['info-color']};
            }
        }
        .notification-body{
            box-shadow: 0 10px 30px ${({ theme }) => theme['light-color']}10;
            .ant-card{
                margin-bottom: 0 !important;
            }
            .ant-card-body{
                padding: 5px 0 !important;
            }
            nav{
                li{
                    padding: 15px 25px !important;
                    @media only screen and (max-width: 575px){
                        padding: 15px 20px !important;
                    }
                    &:not(:last-child){
                        border-bottom: 1px solid ${({ theme }) => theme['border-color-light']};
                    }
                }
            }
        }
        .notification-list-single{
            .notification-list-single__title{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 2px;
                color: ${({ theme }) => theme['gray-color']};
            }
            p{
                margin-bottom: 3px;
                color: ${({ theme }) => theme['light-color']};
            }
        }
    }
    .notification-actions{
        margin: 26px 0 11px;
        button{
            border-radius: 6px;
            height: 44px;
            margin-bottom: 15px;
        }
    }
`;

const gradeAvatarStyle = score => {
  let color = '#7f8c8d';

  switch (Math.floor(score)) {
    case 1:
      color = '#c0392b';
      break;
    case 2:
      color = '#f39c12';
      break;
    case 3:
      color = '#2ecc71';
      break;
    default:
      break;
  }
  return {
    backgroundColor: color,
    verticalAlign: 'middle',
  };
};

export { ConfirmGradeListWrapper, gradeAvatarStyle };
