<template>
  <sdModal type="primary" title="เลือกนักเรียน" :visible="visible" :onCancel="onClose">
    <SearchResultWrapper>
      <a-row :gutter="25">
        <a-col :xs="24">
          <a-input-search
            v-model:value="searchKeyword"
            placeholder="พิมพ์คำที่ต้องการค้นหา"
            size="large"
            @change="onSearch"
          ></a-input-search>
        </a-col>
        <a-col :xs="24">
          <ResultList>
            <sdCards headless v-if="searchResults && searchResults.length > 0">
              <div class="result-list-top">
                <a-row>
                  <a-col :md="13" :xs="24">
                    <p class="search-found-text">
                      พบนักเรียน
                      <span class="result-count">{{ searchResults.length }}</span>
                      คน จากคำว่า
                      <span class="result-keyword">"{{ searchKeyword }}"</span>
                    </p>
                  </a-col>
                </a-row>
              </div>
              <div class="result-list-content">
                <a-row>
                  <a-col md="24">
                    <nav>
                      <ul>
                        <li
                          v-for="student in searchResults"
                          :key="student.id"
                          :style="{ cursor: 'pointer' }"
                          @click="onSelectSearchResult(student)"
                        >
                          <CardStyleWrapper>
                            <sdUserCard>
                              <div class="card user-card theme-list">
                                <sdCards headless>
                                  <figure>
                                    <img
                                      width="50"
                                      :src="require(`@/static/img/students/${student.gender.toLowerCase()}.png`)"
                                      alt=""
                                    />

                                    <figcaption>
                                      <div class="card__content">
                                        <sdHeading class="card__name" as="h6">
                                          <a to="#">{{ student.firstname }} {{ student.lastname }}</a>
                                        </sdHeading>
                                        <p class="card__designation">
                                          {{ student.nickname ? `(${student.nickname})` : '-' }}
                                        </p>
                                        <p class="card-text">เลขที่ {{ student.studentNo }}</p>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </sdCards>
                              </div>
                            </sdUserCard>
                          </CardStyleWrapper>
                        </li>
                      </ul>
                    </nav>
                  </a-col>
                </a-row>
              </div>
            </sdCards>
            <sdCards headless v-else>
              <a-empty description="ไม่พบคุณลักษณะที่ค้นหา"></a-empty>
            </sdCards>
          </ResultList>
        </a-col>
      </a-row>
    </SearchResultWrapper>
  </sdModal>
</template>

<script>
import PropTypes from 'vue-types';
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

import { CardStyleWrapper } from '../../../uiElements/ui-elements-styled';
import { SearchResultWrapper, ResultList } from '../../style';

export default {
  props: {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onSelectStudent: PropTypes.func,
    classroomId: PropTypes.number,
  },
  components: { CardStyleWrapper, SearchResultWrapper, ResultList },
  setup(props) {
    const { getters } = useStore();

    const students = computed(() => getters.getStudentsByClassroom(props.classroomId));

    const searchResults = ref(null);
    const searchKeyword = ref(null);

    watch(
      () => students.value,
      students => {
        searchResults.value = students;
      },
    );

    const onSearch = () => {
      if (searchKeyword.value.length <= 0) {
        searchResults.value = students.value;
        return;
      }

      const results = students.value.filter(student => {
        const nickname = student.nickname ? student.nickname : '';
        return (
          student.firstname.includes(searchKeyword.value) ||
          student.lastname.includes(searchKeyword.value) ||
          nickname.includes(searchKeyword.value)
        );
      });

      searchResults.value = results;
    };

    const onSelectSearchResult = student => {
      props.onSelectStudent(student);
      props.onClose();
    };

    onMounted(() => {
      searchResults.value = students.value;
    });

    return {
      //COMPUTED
      students,
      //REF
      searchResults,
      searchKeyword,
      //FUNCTION
      onSearch,
      onSelectSearchResult,
    };
  },
};
</script>
