<template>
  <div>
    <sdBreadcrumb :routes="routes" />
    <sdPageHeader>
      <template #title> สรุปผลพัฒนาการ: {{ classroom.title }} </template>
      <template #buttons>
        <div class="page-header-actions"></div>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="25">
        <a-col :xs="24">
          <SupportContentWrap>
            <div class="sDash-support-container">
              <div class="sDash_grade-confirm-block">
                <a-spin :spinning="isLoading">
                  <sdCards>
                    <template #title>
                      <div class="confirm-grade-card-title">
                        <sdHeading as="h4">เลือกนักเรียน</sdHeading>
                        <span>เลือกนักเรียนจากภายในห้องที่เลือก</span>
                      </div>
                    </template>
                    <template #button>
                      <sdButton class="btn-ticket" size="large" type="primary" raised @click="onShowSelectStudent">
                        เลือกนักเรียน
                      </sdButton>
                    </template>
                    <div class="sDash-support-links" v-if="selectedStudent">
                      <a-row :gutter="30" justify="center">
                        <a-col :xs="24">
                          <StudentCard class="sDash-support-link-item" :student="selectedStudent" />
                        </a-col>
                      </a-row>
                    </div>
                    <sdCards headless v-else>
                      <a-empty description="กรุณาเลือกนักเรียน"></a-empty>
                    </sdCards>
                  </sdCards>
                </a-spin>
              </div>

              <div class="sDash_grade-confirm-block" v-if="selectedStudent">
                <a-spin :spinning="isLoading">
                  <sdCards>
                    <template #title>
                      <div class="confirm-grade-card-title">
                        <sdHeading as="h4">สรุปผลการประเมินพัฒนาการ</sdHeading>
                        <span
                          >ตามคุณลักษณะที่พึงประสงค์การศึกษาปฐมวัย ({{
                            getGradeLevelTitle(classroom.gradeLevel)
                          }})</span
                        >
                      </div>
                    </template>
                    <template #button>
                      <sdButton
                        class="btn-ticket"
                        size="large"
                        type="primary"
                        raised
                        @click="onOpenClassroomEvaluation"
                      >
                        ไปที่หน้าบันทึกพัฒนาการ
                      </sdButton>
                    </template>
                    <ConfirmGradeListWrapper v-if="attributeGrades">
                      <a-row :gutter="[10, 10]">
                        <a-col :xs="24" v-if="isSaved">
                          <sdAlerts
                            :outlined="false"
                            :closable="false"
                            :showIcon="true"
                            message="คุณยืนยันผลพัฒนาการไปแล้ว"
                            description="คุณจะไม่สามารถแก้ไขผลของพัฒนาการได้อีก"
                            type="success"
                          />
                        </a-col>
                        <a-col :xs="24" v-else-if="unsaveable">
                          <sdAlerts
                            :outlined="false"
                            :closable="false"
                            :showIcon="true"
                            message="ไม่สามารถบันทึกสรุปผลพัฒนาการได้"
                            description="เพราะข้อมูลบันทึกพัฒนาการยังมีไม่ครบทั้ง 2 ภาคเรียน"
                            type="warning"
                          />
                        </a-col>
                        <a-col :xs="24" v-for="topic in topicList" :key="`tp${topic.id}`">
                          <div class="notification-box-single">
                            <sdCards headless :bodyStyle="{ backgroundColor: '#F7F8FA', borderRadius: 10 }">
                              <div
                                :style="{
                                  height: '50px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }"
                                class="notification-header"
                              >
                                <sdHeading class="notification-header__text" as="h4">
                                  {{ topic.title }}
                                </sdHeading>
                              </div>
                              <div class="notification-body">
                                <sdCards headless>
                                  <nav>
                                    <ul
                                      :style="{
                                        margin: 0,
                                        padding: 0,
                                      }"
                                    >
                                      <li v-for="attribute in topic.attributes" :key="`attr${attribute.id}`">
                                        <a-row justify="space-between" :gutter="8">
                                          <a-col :xs="24" :md="18">
                                            <div class="notification-list-single">
                                              <sdHeading class="notification-list-single__title" as="h4">
                                                มาตรฐานที่ {{ attribute.order }}
                                              </sdHeading>
                                              <p>
                                                {{ attribute.title }}
                                              </p>
                                            </div>
                                          </a-col>
                                          <a-col :xs="24" :md="3" v-for="term in terms" :key="`tm${term.id}`">
                                            <div :style="{ 'text-align': 'right' }">
                                              <span :style="{ 'margin-right': '10px' }"
                                                >ภาคเรียน {{ term.termNo }}</span
                                              >
                                              <a-avatar
                                                shape="square"
                                                size="small"
                                                :style="
                                                  gradeAvatarStyle(
                                                    attributeGrades[`${term.id}`][`${attribute.id}`][`${topic.id}`],
                                                  )
                                                "
                                              >
                                                {{ attributeGrades[`${term.id}`][`${attribute.id}`][`${topic.id}`] }}
                                              </a-avatar>
                                            </div>
                                          </a-col>
                                        </a-row>
                                      </li>
                                    </ul>
                                  </nav>
                                </sdCards>
                              </div>
                              <div
                                :style="{
                                  'margin-top': '20px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }"
                              >
                                <a-radio-group
                                  v-model:value="topic.finalTopicScore"
                                  button-style="solid"
                                  :disabled="unsaveable"
                                >
                                  <a-radio-button :value="3">ดี - 3</a-radio-button>
                                  <a-radio-button :value="2">พอใช้ - 2</a-radio-button>
                                  <a-radio-button :value="1">ความส่งเสริม - 1</a-radio-button>
                                </a-radio-group>
                              </div>
                            </sdCards>
                          </div>
                        </a-col>
                      </a-row>
                    </ConfirmGradeListWrapper>

                    <a-row class="notification-actions" justify="center">
                      <sdButton size="default" type="primary" @click="onSubmit" :disabled="unsaveable">
                        ยืนยันคะแนน
                      </sdButton>
                    </a-row>
                  </sdCards>
                </a-spin>
              </div>
            </div>
          </SupportContentWrap>
        </a-col>
      </a-row>
    </Main>
  </div>
  <SearchStudent
    :visible="showSelectStudent"
    :classroomId="classroomId"
    :onClose="onCloseSelectStudent"
    :onSelectStudent="onSelectStudent"
  />
</template>

<script>
import PropTypes from 'vue-types';

import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Main } from '../../styled';
import StudentCard from './overview/StudentCard.vue';
import { ConfirmGradeListWrapper, gradeAvatarStyle } from './style';
import { SupportContentWrap } from '../../pages/style';

import { getGradeLevelTitle } from '@/components/utilities/utilities';
import SearchStudent from './overview/SearchStudent.vue';

const listStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  padding: 0,
};

export default {
  name: 'Settings',
  components: {
    Main,
    StudentCard,
    SearchStudent,
    SupportContentWrap,
    ConfirmGradeListWrapper,
  },
  props: {
    classroomId: PropTypes.string,
    schoolYearId: PropTypes.string,
  },
  async setup(props) {
    const router = useRouter();
    const { dispatch, state, getters } = useStore();

    const schoolYear = computed(() => getters.selectedYear());
    const topicList = computed(() => state.evaluateForm.currentForm);
    const attrScores = computed(() => state.gradeConfirmation.currentRecords);
    const topicGrades = computed(() => state.gradeConfirmation.currentTopicGradeRecords);
    const classroom = computed(() => getters.getClassroomById(props.classroomId));
    const terms = computed(() => getters.selectedYearTerms());

    const classroomLoading = computed(() => state.classroom.loading);
    const formLoading = computed(() => state.evaluateForm.loading);
    const gradeLoading = computed(() => state.gradeConfirmation.loading);
    const isLoading = computed(() => classroomLoading.value || formLoading.value || gradeLoading.value);

    const isSaved = computed(() => {
      if (!topicGrades.value) {
        return false;
      }
      if (topicGrades.value.length > 0) {
        const foundNoScore = topicGrades.value.find(item => !item.score);
        if (foundNoScore) {
          return false;
        }
      }
      return topicGrades.value.length == 4;
    });

    const unsaveable = computed(() => {
      if (!topicGrades.value) {
        return true;
      } else {
        if (noScoreDetector.value > 0) {
          // มีบางข้อไม่มีคะแนน
          console.log('มีบางข้อไม่มีคะแนน');
          return true;
        } else if (isSaved.value) {
          // บันทึกไปแล้ว
          console.log('บันทึกไปแล้ว');
          return true;
        } else if (topicGrades.value.length == 0 && noScoreDetector.value == 0) {
          // ยังไม่บันทึก และ มีคะแนนครบ
          console.log('ยังไม่บันทึก และ มีคะแนนครบ');
          return false;
        }
        return true;
      }
    });

    const routes = computed(() => {
      return [
        {
          path: '',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: `/classroom`,
          breadcrumbName: 'ห้องเรียนทั้งหมด',
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classrooms/${props.classroomId}`,
          breadcrumbName: classroom.value.title,
        },
        {
          path: `/classroom/${props.classroomId}/grade-confirmation`,
          breadcrumbName: 'สรุปผลพัฒนาการ',
        },
      ];
    });

    const showSelectStudent = ref(false);
    const selectedStudent = ref(null);
    const attributeGrades = ref(null);
    const noScoreDetector = ref(0);

    const onShowSelectStudent = () => {
      showSelectStudent.value = true;
    };

    const onCloseSelectStudent = () => {
      showSelectStudent.value = false;
    };

    const onOpenClassroomEvaluation = () => {
      router.push({
        name: 'classroom-evaluation',
        props: {
          schoolYearId: schoolYear.value.id,
          classroomId: props.classroomId,
        },
      });
    };

    const onSelectStudent = async student => {
      selectedStudent.value = student;
      noScoreDetector.value = 0;
      onCloseSelectStudent();

      await fetchStudentAttributeScore();
      await fetchStudentGrades();

      attributeGrades.value = {};

      topicList.value.forEach(topic => {
        topic.attributes.forEach(attribute => {
          terms.value.forEach(term => {
            const value = getAttributeScoreWithTerm(term.id, attribute.id, topic.id);

            if (!value) {
              noScoreDetector.value = noScoreDetector.value + 1;
            }

            if (!attributeGrades.value[`${term.id}`]) {
              attributeGrades.value[`${term.id}`] = {};
            }

            if (!attributeGrades.value[`${term.id}`][`${attribute.id}`]) {
              attributeGrades.value[`${term.id}`][`${attribute.id}`] = {};
            }

            if (!attributeGrades.value[`${term.id}`][`${attribute.id}`][`${topic.id}`]) {
              attributeGrades.value[`${term.id}`][`${attribute.id}`][`${topic.id}`] = {};
            }

            attributeGrades.value[`${term.id}`][`${attribute.id}`][`${topic.id}`] = value ?? '-';
          });
        });
      });

      topicList.value.forEach(topic => {
        if (!topicGrades.value || topicGrades.value.length == 0) {
          topic.finalTopicScore = presetFinalTopicScore(topic.id);
        } else {
          topic.finalTopicScore = getFinalTopicScore(topic.id);
        }
      });
    };

    const getFinalTopicScore = topicId => {
      if (!topicGrades.value) return null;

      const foundGrade = topicGrades.value.find(grade => {
        return grade.developmentTopicId == topicId;
      });

      if (!foundGrade) return null;

      return foundGrade.score;
    };

    const presetFinalTopicScore = topicId => {
      const studentkey = `student_${selectedStudent.value.id}`;
      const topicKey = `topic_${topicId}`;
      if (!attrScores.value || !attrScores.value[studentkey]) {
        return null;
      }

      const topic = attrScores.value[studentkey][topicKey];
      const lastTermId = terms.value.find(term => term.termNo == 2).id;
      const attributes = Object.values(topic);
      const attributeSummation = attributes.reduce((acc, attribute) => {
        acc = acc + attribute[`term_${lastTermId}`];
        return acc;
      }, 0);

      const result = attributeSummation / attributes.length;

      const score = Math.round(result);

      return score;
    };

    const fetchTopicsList = () => {
      return new Promise((resolve, reject) => {
        dispatch('getEvaluateForm', {
          standardYear: schoolYear.value.standardYear,
          gradeLevel: classroom.value.gradeLevel,
        })
          .then(val => {
            resolve(val);
            console.log({ topicList: val });
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentAttributeScore = () => {
      return new Promise((resolve, reject) => {
        dispatch('getAttributeScores', {
          schoolYearId: props.schoolYearId,
          classroomId: props.classroomId,
          studentId: selectedStudent.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const fetchStudentGrades = () => {
      return new Promise((resolve, reject) => {
        dispatch('getTopicGrade', {
          schoolYearId: props.schoolYearId,
          classroomId: props.classroomId,
          studentId: selectedStudent.value.id,
        })
          .then(val => {
            resolve(val);
          })
          .catch(err => {
            reject(err);
          });
      });
    };

    const getAttributeScoreWithTerm = (termId, attrId, topicId) => {
      const studentkey = `student_${selectedStudent.value.id}`;
      const topicKey = `topic_${topicId}`;

      if (!attrScores.value || !attrScores.value[studentkey]) {
        return;
      }

      const attributeKey = `attribute_${attrId}`;
      const termKey = `term_${termId}`;
      const score = attrScores.value[studentkey][topicKey][attributeKey][termKey];

      return score;
    };

    const onSubmit = () => {
      const records = [
        {
          studentId: selectedStudent.value.id,
          topics: topicList.value.map(topic => {
            return {
              id: topic.id,
              score: topic.finalTopicScore,
            };
          }),
        },
      ];

      dispatch('updateTopicGradeRecords', {
        schoolYearId: schoolYear.value.id,
        classroomId: classroom.value.id,
        postData: { records },
      });
    };

    onMounted(async () => {
      await fetchTopicsList();
    });

    return {
      // STYLE
      listStyle,
      gradeAvatarStyle,
      // COMPUTED
      terms,
      topicList,
      attrScores,
      isLoading,
      unsaveable,
      isSaved,
      classroom,
      routes,
      // REF
      attributeGrades,
      selectedStudent,
      showSelectStudent,
      // FUNCTION
      onSelectStudent,
      onShowSelectStudent,
      onCloseSelectStudent,
      onOpenClassroomEvaluation,
      onSubmit,
      getAttributeScoreWithTerm,
      getGradeLevelTitle,
    };
  },
};
</script>
